import React, { useState, useEffect } from 'react';
import { Grid, Paper, TextField, Typography, OutlinedInput, InputAdornment, IconButton, Button, FormControl, Select, InputLabel, MenuItem, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { apiGetRequest, apiRequest } from '../config';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Loading from '../components/loading';
import { useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

const MyProfile = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
    const [alignment, setAlignment] = useState(1);
    const [bday, setBday] = useState(1);
    const [statuscoup, setStatuscoup] = useState("0");
    const [dateRange, setDateRange] = useState([null, null]);
    const [opensnack, setOpenSnack] = useState(false);
    const [status, setStatus] = useState("error");
    const [statustext, setStatustext] = useState("");
    const [resetpassopen, setResetpassopen] = useState(false);
    const [newpass, setNewpass] = useState({});
    const [showPassword, setShowPassword] = React.useState(false);
    const [passerror, setPasserror] = React.useState();
    useEffect(() => {
      
        document.title = "Profilim | Torexch";
      
      
      }, []);
    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("authToken");

        apiGetRequest("/api/user/myprofile", token)
            .then(response => {
                if (response) {
          
                    setData(response);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [dateRange, statuscoup, alignment]);

    const resetpassclose = () => {
        setResetpassopen(!resetpassopen);
    };

    const passchange = (value, id) => {
        const updatedPass = { ...newpass };
        updatedPass[id] = value;
        setNewpass(updatedPass);
      
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const resetpass=()=>{
        if(newpass?.newpass==newpass?.renewpass){
            const token = localStorage.getItem("authToken");
            apiRequest("/api/user/changemypass", {oldpass:newpass?.lastpass,newpass:newpass.newpass}, token)
                .then(response => {
                    if (response?.success === true) {
                        setOpenSnack(true);
                        setStatus("success");
                        setStatustext("Değişiklikleriniz Kaydedildi.");
                        setResetpassopen(false);
                    } else {
                        setOpenSnack(true);
                        setStatus("error");
                        setStatustext("Hata lütfen tekrar deneyiniz.");
                        setPasserror("Eski Şifre Yanlış")
                    }
                });
        }else{
            setPasserror("Şifreler Uyuşmuyor")
        }
    }
    const savedata = () => {
        const token = localStorage.getItem("authToken");
        apiRequest("/api/user/updateprofile", data, token)
            .then(response => {
                if (response.success === true) {
                    setData(response.data);
                    setOpenSnack(true);
                    setStatus("success");
                    setStatustext("Değişiklikleriniz Kaydedildi.");
                } else {
                    setOpenSnack(true);
                    setStatus("error");
                    setStatustext("Hata lütfen tekrar deneyiniz.");
                }
            });
    };

    const changebday = (e) => {
        setBday(e.$d);
        const date = new Date(e.$d);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        data["birthday"] = `${day}-${month}-${year}`;
    };

    const confdata = (value, id) => {
        data[id] = value;
        setData(data);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handleDateChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    const [expandedRows, setExpandedRows] = useState({});

    dayjs.locale('tr');
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" sx={{ paddingTop: "1%" }}>
                <Grid item xs={11} sm={11} md={8} lg={8}>
                    <Typography variant='h3' sx={{ fontWeight: "200", fontSize: isSmallScreen ? "2rem" : "3rem" }}>PROFİLİM</Typography>
                    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px', borderBottom: "solid" }}>
                        <Grid container spacing={1} sx={{ borderBottom: "solid 1px #646464" }}>
                            <Grid item xs={6} sm={6} md={6} lg={6} >
                                <Typography gutterBottom variant={"h4"} sx={{ fontWeight: "200", marginBottom: "5px" }}>Bilgiler</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "center" }}>
                                <Grid container sx={{ justifyContent: "end", alignItems: "center" }}>
                                    <Grid item sx={{ padding: "2px", borderRadius: "3px", backgroundColor: data.verification_status == 1 ? "green" : "red", width: "60px" }}>
                                        <Typography gutterBottom sx={{ fontWeight: "400", color: "#fff", marginBottom: "0px" }}>{data.verification_status == 1 ? "Onaylı" : "Onaysız"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Id:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.id} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "id") }} disabled />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Username:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.username} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "username") }} disabled />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>E-Posta:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.email} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "email") }} disabled />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Telefon:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.tel} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "tel") }} disabled />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Button variant="contained" size="small" onClick={() => setResetpassopen(true)}>Şifremi Değiştir</Button>
                            </Grid>
                        </Grid>
                        <Grid sx={{ width: "100%", borderBottom: "solid 1px #646464", marginTop: "20px" }}>
                            <Typography gutterBottom variant={"h4"} sx={{ fontWeight: "200", marginBottom: "5px" }}>Kişisel Bilgiler</Typography>
                        </Grid>
                        <Grid container spacing={1} sx={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Ad:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.firstname} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "firstname") }} disabled={data?.firstname ? true : false} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Soyad:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.lastname} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "lastname") }} disabled={data?.lastname ? true : false} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Tc No:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.tcno} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "tcno") }} disabled={data?.tcno ? true : false} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Doğum Tarihi:</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker sx={{ width: "100%" }} defaultValue={dayjs(data?.birthday)} onChange={(e) => { changebday(e) }} disabled={data?.birthday ? true : false} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Cinsiyet:</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={data?.gender}
                                        onChange={(e) => { confdata(e.target.value, "gender") }}
                                        disabled={data?.gender ? true : false}
                                    >
                                        <MenuItem value={"male"}>Erkek</MenuItem>
                                        <MenuItem value={"female"}>Kadın</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Ülke:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.country} onChange={(e) => { confdata(e.target.value, "country") }} sx={{ width: "100%" }} disabled={data?.country ? true : false} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Şehir:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.city} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "city") }} disabled={data?.city ? true : false} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Posta Kodu:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.post_code} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "post_code") }} disabled={data?.post_code ? true : false} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Adres:</Typography>
                                <TextField id="outlined-basic" variant="outlined" value={data.address} sx={{ width: "100%" }} onChange={(e) => { confdata(e.target.value, "address") }} disabled={data?.address ? true : false} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container sx={{ justifyContent: "end", alignItems: "center" }}>
                                    <Button variant="contained" size="large" sx={{ bgcolor: "green" }} onClick={() => savedata()}>KAYDET</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    color={status}
                    variant="filled"
                    sx={{ width: '100%', color: "#fff" }}
                >
                    {statustext}
                </Alert>
            </Snackbar>
            <Dialog open={resetpassopen} onClose={resetpassclose} PaperProps={{ sx: { width: '400px' } }}>
                <DialogTitle>Şifremi Unuttum</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Eski Şifre:</Typography>
                    <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => passchange(e.target.value, "lastpass")}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {passerror=="Eski Şifre Yanlış"&&<Typography gutterBottom>{passerror}</Typography>}
                    <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Yeni Şifre:</Typography>
                    <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => passchange(e.target.value, "newpass")}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Typography gutterBottom sx={{ fontSize: "1.2rem", marginBottom: "0px", fontWeight: "500" }}>Yeni Şifre Tekrar:</Typography>
                    <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => passchange(e.target.value, "renewpass")}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {passerror=="Şifreler Uyuşmuyor"&&<Typography gutterBottom>{passerror}</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetpassclose} color="primary">
                        İptal
                    </Button>
                    <Button color="primary" onClick={resetpass} variant="contained">
                        Gönder
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MyProfile;
