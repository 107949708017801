import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Grid} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    padding: theme.spacing(1, 0), // Add vertical padding for aesthetics
    whiteSpace: 'nowrap', // Prevents wrapping of items
  },
  button: {
    flex: 'none', // Each button keeps its intrinsic width
    cursor: 'pointer', // Indicate buttons are clickable
    padding: theme.spacing(1, 2), // Uniform padding around content
    marginRight: theme.spacing(1), // Space between buttons
    border: 'none', // Removes any default border styling
    textTransform: 'none',
    backgroundColor: '#272727', // Prevents uppercase transformation typical in buttons
    '&:hover': {
      backgroundColor: '#ffb80c', // Hover effect for better UX
    },
  },
  activeButton: {
    borderBottom: '2px solid #ffb80c', // Highlight for the active market
  }
}));

const MarketMenu = ({ activemarket, setActiveMarket, marketInfo,marketTogle}) => {
  const classes = useStyles();

  if(activemarket.length==0){
    if(marketInfo[0]){
      setActiveMarket({ market: marketInfo[0].marketId, status: true })
    }
  }
  
  return (
    <>
    <Grid container justifyContent="center" sx={{ overflow: 'hidden', width: '100%' }}>
      <div className={classes.scrollableContainer}>
      <Button
            key={"ALL"}
            className={`${classes.button}`}
            onClick={() => marketTogle(true)}
          >
            <Typography variant="body2">Tüm Marketler</Typography>
          </Button>
        {marketInfo.map((market) => (
          <Button
            key={market.marketId}
            className={`${classes.button} ${activemarket.market === market.marketId ? classes.activeButton : ''}`}
            onClick={() => setActiveMarket({ market: market.marketId, status: true })}
          >
            <Typography variant="body2">{market.marketName}</Typography>
          </Button>
        ))}
      </div>
    </Grid>
        </>
  );
};

export default MarketMenu;
