import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItem, Typography, Collapse, Grid, ListItemIcon, Button, useMediaQuery,TextField,InputBase} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { apiGetFeedRequest } from '../config';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(3),
  },
  listItemIcon: {
    minWidth: 'unset', // Remove the minimum width
    marginRight: theme.spacing(1), // Adjust margin as needed
  },
}));

const LigMenu = React.memo(({ activeleague, setLeague }) => {
  const [ligler, setLigler] = useState([]);
  const [val, setVal] = useState("");
  const [ulkelers, setUlkeler] = useState([]);
  const [ulkeler2, setUlkeler2] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for initial data fetching
  const isMobile = useMediaQuery('(max-width:1280px)');

  useEffect(() => {
    const token = localStorage.getItem("authToken");

      apiGetFeedRequest("/api/competitions/listcompetitions", token)
        .then(response => {
          setLigler(response);
          localStorage.setItem('competitions', JSON.stringify(response)); // Cache competitions
        })
        .then(() => {
          apiGetFeedRequest("/api/competitions/listcountry", token)
            .then(response => {
              setUlkeler(response);
              localStorage.setItem('countries', JSON.stringify(response)); // Cache countries
              setLoading(false);
            })
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    
  }, []);

  const classes = useStyles();
  const [open, setOpen] = useState({});
  const ulkeler = [...new Set(ligler.map((lig) => lig.competitionRegion))];

  const searchCountry = (value) => {
    setVal(value)
};

useEffect(() => {
  ulkeler.filter
}, [val])


  const getUlkeNameById = (id) => {
    const ulke = ulkelers?.find((ulke) => ulke.id === id);
    return ulke ? ulke.name : "";
  };

  const handleClick = (ulkeId) => {
    setOpen(prevOpen => ({
      ...prevOpen,
      [ulkeId]: !prevOpen[ulkeId]
    }));
  };

  const [activeButton, setActiveButton] = useState('favleagues');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  return (
    <>
      <Grid sx={{ width: "90%", paddingLeft: "15px", paddingTop: isMobile ? "30px" : "20px" }}>

        <div style={{ backgroundColor: "#272727", border: "solid 1px #545454" }}>
          <Button
            variant="text"
            onClick={() => handleButtonClick('countrys')}
            style={{
              width: "50%",
              height: "100%",
              fontSize: "0.7rem",
              textDecoration: activeButton === 'countrys' ? 'underline' : 'none',
              backgroundColor: activeButton === 'countrys' ? '#ffb80c' : '#272727',
            }}
          >
            Tümü
          </Button>
          <Button
            variant="text"
            onClick={() => handleButtonClick('favleagues')}
            style={{
              width: "50%",
              fontSize: "0.7rem",
              textDecoration: activeButton === 'favleagues' ? 'underline' : 'none',
              backgroundColor: activeButton === 'favleagues' ? '#ffb80c' : '#272727',
            }}
          >
            En İYİ
          </Button>
        </div>
      </Grid>
      <Grid sx={{ width: "90%", paddingLeft: "15px", paddingTop: "5px", paddingBottom: isMobile ? "20px" : "0px" }}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            {activeButton == "countrys" ? (
              <List sx={{ backgroundColor: "#272727", border: "solid 1px #545454" }}>
                <Grid sx={{ borderBottom: "solid 1px #fff" }}>
                  <Typography sx={{ color: "#fff", paddingLeft: "15px", fontWeight: 600 }} small>Tüm Ülkeler</Typography>
                </Grid>
                <InputBase
                    sx={{paddingLeft:"5px",marginTop:"5px",flex: 1,borderTop:"solid 1px",borderBottom:"solid 1px"}}
                    placeholder="Ülke Ara"
                    inputProps={{ 'aria-label': 'Ülke Ara',color:"#ffb80c" }}
                    onChange={(e)=>{searchCountry(e.target.value)}}
                  />
                {ulkeler.map((ulkeId, index) => {
                  const ulkeName = getUlkeNameById(ulkeId);
                  const filteredLigler = ligler.filter((lig) => lig.competitionRegion === ulkeId);
                  
                  return (
                    <React.Fragment key={ulkeId}>
                      {ulkeName.toLowerCase().includes(val.toLowerCase())&&
                      <>
                      <ListItem button onClick={() => handleClick(ulkeId)} sx={{ padding: "0px", borderBottom: "solid 1px" }}>
                        <Typography variant="body2" sx={{ fontSize: "0.8rem", paddingLeft: "15px", flex: '1' }}>{ulkeName}</Typography>
                        <ListItemIcon className={classes.listItemIcon}>
                          {open[ulkeId] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                      </ListItem>
                      <Collapse in={open[ulkeId]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {filteredLigler.map((filteredLig, index) => (
                            <React.Fragment key={filteredLig.id}>
                              <ListItem button className={classes.nested} onClick={() => setLeague(filteredLig.id,filteredLig.name)} sx={{ padding: "0px", backgroundColor: filteredLig.id == activeleague ? "#ffb80c" : "#3d3d3d", color: filteredLig.id == activeleague ? "#fff" : "#838282", borderBottom: "solid 1px #626262" }}>
                                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>{filteredLig.name}</Typography>
                              </ListItem>
                            </React.Fragment>
                          ))}
                        </List>
                      </Collapse></>}
                    </React.Fragment>
                  ); 
                })}
              </List>
            ) : (
              <>
                <List sx={{ backgroundColor: "#272727", border: "solid 1px #545454" }}>
                  <Grid sx={{ borderBottom: "solid 1px #fff" }}>
                    <Typography sx={{ color: "#fff", paddingLeft: "15px", fontWeight: 600 }}>En İyi Ligler</Typography>
                  </Grid>
                  {ligler.map((lig, index) => {
                    return (
                      index < 11 &&
                      <React.Fragment key={lig.id}>
                        <ListItem button onClick={() => setLeague(lig.id,lig.name)} sx={{ padding: "3px 0px", borderBottom: "solid 1px", backgroundColor: lig.id == activeleague ? "#ffb80c" : "#3d3d3d", color: lig.id == activeleague ? "#fff" : "#ffb80c" }}>
                          <Typography variant="body2" sx={{ fontSize: "0.8rem", paddingLeft: "15px", flex: '1' }}>{lig.name}</Typography>
                        </ListItem>
                      </React.Fragment>
                    );
                  })}
                </List>
                <List sx={{ backgroundColor: "#272727", border: "solid 1px #545454", marginTop: "15px" }}>
                  <Grid sx={{ borderBottom: "solid 1px #fff" }}>
                    <Typography sx={{ color: "#fff", paddingLeft: "15px", fontWeight: 600 }}>En İyi Ülkeler</Typography>
                  </Grid>
                  {ulkeler.map((ulkeId, index) => {
                    const ulkeName = getUlkeNameById(ulkeId);
                    const filteredLigler = ligler.filter((lig) => lig.competitionRegion === ulkeId);
                    return (
                      index < 11 &&
                      <React.Fragment key={ulkeId}>
                        <ListItem button onClick={() => handleClick(ulkeId)} sx={{ padding: "0px", borderBottom: "solid 1px" }}>
                          <Typography variant="body2" sx={{ fontSize: "0.8rem", paddingLeft: "15px", flex: '1' }}>{ulkeName}</Typography>
                          <ListItemIcon className={classes.listItemIcon}>
                            {open[ulkeId] ? <ExpandLess /> : <ExpandMore />}
                          </ListItemIcon>
                        </ListItem>
                        <Collapse in={open[ulkeId]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {filteredLigler.map((filteredLig, index) => (
                              <React.Fragment key={filteredLig.id}>
                                <ListItem button className={classes.nested} onClick={() => setLeague(filteredLig.id,filteredLig.name)} sx={{ padding: "0px", backgroundColor: filteredLig.id == activeleague ? "#ffb80c" : "#3d3d3d", color: filteredLig.id == activeleague ? "#fff" : "#838282", borderBottom: "solid 1px #626262" }}>
                                  <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>{filteredLig.name}</Typography>
                                </ListItem>
                              </React.Fragment>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    );
                  })}
                </List>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
});

export default LigMenu;
