import React,{useEffect} from 'react';
import { Box, Button, Typography,Grid,Paper } from '@mui/material';
import SoccerBallIcon from '../../media/ball.png';  // Futbol topu SVG ikonu
import YellowCardIcon from '../../media/yellowcard.svg';  // Sarı kart SVG ikonu
import RedCardIcon from '../../media/redcard.svg'; 
// Olaylar ve maç durumunu simüle eden veri
const eventData = {
  matchStatus: "Home 3-2 Away",
  totalDuration: 90, 
  events: [
    { type: "goal", team: "home", minute: 12 },
    { type: "goal", team: "away", minute: 22 },
    { type: "goal", team: "home", minute: 35 },
    { type: "goal", team: "home", minute: 65 },
    { type: "goal", team: "away", minute: 75 },
    { type: "yellow-card", team: "home", minute: 45 },
    { type: "red-card", team: "away", minute: 55 }
  ]
};

const TimelineItem = ({ event, totalDuration }) => {

    const offset = (event.matchTime / totalDuration) * 100;
    const positionStyle = event.team === 'home' ? 
      { bottom: '100%', marginBottom: '1px',zIndex:"999" } : { top: '100%', marginTop: '-7px',zIndex:"999" };
    const icon = event.type === "Goal" ? SoccerBallIcon :
                 event.type === "YellowCard" ? YellowCardIcon :
                 event.type === "RedCard" && RedCardIcon ;
    return (
        event.type === "Goal" || event.type === "YellowCard" || event.type === "RedCard" ?
      <Box sx={{ position: 'absolute', left: `${offset}%`, ...positionStyle }}>
        <img src={icon} alt={event.type} style={{width: event.type==="Goal"?'12px':'9px', height: '12px' }} />
      </Box>:""
    );
  };

function MatchTimeline({liveinfo,matchname,eventType,openDate,competition,matchid}) {
  const progressWidth = (liveinfo?.timeElapsed /  (90 + (liveinfo?.elapsedAddedTime?liveinfo?.elapsedAddedTime:0))) * 100;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC' // Saat dilimini belirtiyoruz
    };
    const formattedDate = date.toLocaleDateString('tr-TR', options);
    return formattedDate;
}
  return (
    <>
    <Grid container key={matchid} alignItems="center" sx={{ alignItems: "center", alignContent: "center", marginTop: "10px", borderBottom: "solid 2px" }} >
     <Grid item xs={12} sx={{ backgroundColor: "#272727" }}>
        {liveinfo?
        <>
        {Object.entries(liveinfo)?.length === 0?
        <>
        <Typography variant="h6" key={"3"} gutterBottom sx={{ marginLeft: "10px", marginTop: "5px",marginBottom:"0px",color:"#fff" }}>{matchname}</Typography>
        <Typography variant="h6" key={"4"}  component="div" sx={{color:"green",fontSize:"1.1rem",marginLeft: "10px",marginBottom:"5px" }}>{eventType=="live"?"Live":"Pre-Match"}</Typography>
        </>
        :
        <>
                <Typography variant="h6" key={"5"}  gutterBottom sx={{ marginLeft: "15px", marginTop: "5px",marginBottom:"-5px",fontSize:"0.9rem" }}>{competition}</Typography>
     <Typography variant="h6" key={"6"}  gutterBottom sx={{ marginLeft: "15px",marginBottom:"-5px",color:"#fff" }}>{liveinfo?.score?.home?.name} <span variant="h6" style={{color:"#ffb80c"}}>{liveinfo?.score?.home?.score}-{liveinfo?.score?.away?.score}</span> {liveinfo?.score?.away?.name}</Typography>
     <Typography variant="h6" key={"7"}  component="div" sx={{color:"green",fontSize:"1.1rem",marginLeft: "15px",marginBottom:"5px" }}>{liveinfo?.inPlayMatchStatus==="FirstHalfEnd"?"IY":liveinfo?.inPlayMatchStatus==="Finished"?"FT":liveinfo?.elapsedRegularTime+"'"} {liveinfo?.score?.home?.halfTimeScore && liveinfo?.score?.away?.halfTimeScore &&"(HT "+liveinfo?.score?.home?.halfTimeScore+"-"+liveinfo?.score?.away?.halfTimeScore+")"}</Typography>
     </>}</>:        <>
        <Typography variant="h6" key={"1"}  gutterBottom sx={{ marginLeft: "10px", marginTop: "5px",marginBottom:"0px",fontSize:"0.9rem" }}>{competition} / {formatDate(openDate)}</Typography>
        <Typography variant="h6" key={"2"}  gutterBottom sx={{ marginLeft: "10px",marginBottom:"10px",color:"#fff" }}>{matchname}</Typography>

        </>}
    </Grid>
    </Grid>
    { liveinfo && Object.entries(liveinfo)?.length>0&&
    <Paper>
    <Box sx={{ position: 'relative', height: '60px', color: 'white', p: 1, pt: 2 }}>
      <Grid sx={{marginTop:"10px"}}>
      <Box sx={{ position: 'relative', height: '10px', width: '100%', bgcolor: 'gray', zIndex: 1 ,borderRadius:"5px"}}>
        <Box sx={{ width: `${progressWidth}%`, height: '100%', bgcolor: 'green',borderRadius:"5px",zIndex: 2 }} >
        <Box sx={{ position: 'absolute', width: '3%', height: '100%', bgcolor: '#1e1e1e', left: '48.5%' }} />
        </Box>
      </Box>
      <Box sx={{ position: 'relative', width: '100%' }}>
        {liveinfo?.updateDetails.map((event, index) => (
          <TimelineItem key={index} event={event} totalDuration={(90 + (liveinfo?.elapsedAddedTime?liveinfo?.elapsedAddedTime:0))} />
        ))}
      </Box>
      </Grid>
    </Box>
    </Paper>}
    </>
  );
}

export default MatchTimeline;
