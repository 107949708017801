import React, { useEffect,useState } from 'react';
import { Grid, Paper, Button, Typography, Stack,Snackbar,Alert} from '@mui/material';
import BetPlacingComponent from './slipdesktop';
import { Link } from 'react-router-dom';
const EventDetail = ({ markets,allreadyrisk,recheck,setRecheck,riskcheck,activemarket,isMobile, matchid, matchname,setConfirmedodd,expandedMatchId,setExpandedMatchId,selectedodddetail,setselectedodddetail,selectedmarket,setSelectedmarket,setBalance}) => {
    const [open, setOpen] = React.useState(false);
    const [status,setStatus] = React.useState("error");
    const [statustext,setStatustext] = React.useState();
    
    const handleExpandClick = (matchId, type, price, index, oddname, oddid, marketname, matchname, marketid,location) => {
        const newDetail = {
            matchId,
            type,
            price,
            index,
            oddname,
            oddid,
            marketname,
            matchname,
            marketid,
            location
        };
        setConfirmedodd([])
        setExpandedMatchId(matchId);
        setselectedodddetail(prevDetails => {
            const existingIndex = prevDetails?.findIndex(detail =>
                detail.matchId === matchId && detail.type === type && detail.oddid === oddid && detail.marketid === marketid
            );
            const existingIndex2 = prevDetails?.findIndex(detail =>
                detail.matchId === matchId && detail.type === type && detail.oddid === oddid && detail.marketid === marketid && detail.index === index
            );
            if (existingIndex > -1) {
                if (existingIndex2 > -1) {
                    return prevDetails.filter((_, index) => index !== existingIndex2);
                } else {
                    return prevDetails.map((item, index) =>
                        index === existingIndex ? newDetail : item
                    );
                }

            } else {
                return [...prevDetails, newDetail];
            }
        });

    };
    const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    useEffect(() => {
        const data = markets.filter(item => item.marketId == activemarket.market)
        setSelectedmarket(data[0])
      }, [activemarket,markets])

      const [runnersWithRiskOdd, setRunnersWithRiskOdd] = useState([]);
      useEffect(() => {
        if (markets) {
          const updatedRunners = markets.flatMap((mar) => {
            return mar.runners.map((item) => {
              let calculatedRisk = 0;
              selectedodddetail.forEach((riskItem) => {
                if (riskItem?.amount && riskItem.marketname === mar.marketName) {
                  let laystake = 0;
                  let backstake = 0;
                  let layprofit = 0;
                  let backprofit = 0;
                  if (riskItem.type === "LAY") {
                    laystake += (riskItem.price - 1) * riskItem.amount;
                    layprofit += riskItem.amount;
                  } else {
                    backstake += riskItem.amount;
                    backprofit += (riskItem.price - 1) * riskItem.amount;
                  }
                  if (riskItem.oddname === item.runnerName) {
                    calculatedRisk += backprofit - laystake;
                  } else {
                    calculatedRisk += layprofit - backstake;
                  }
                }
              });
              return { ...item, calculatedRisk };
            });
          });
          setRunnersWithRiskOdd(updatedRunners);
        }
      }, [selectedodddetail, selectedmarket]);
      
      
      

    //   const riskcolor=(id)=>{
    //     const calculatedRisk = runnersWithRisk.length 
    //     ? runnersWithRisk.find(riskitem => riskitem?.runnerName === id)?.calculatedRisk 
    //     : null;
    //     return calculatedRisk
    //   }
        const risksee=(id,market)=>{

            if(allreadyrisk?.length>0)
        { const marketrisk=allreadyrisk[0]?.market_risk.find(item=>item.market_name==market)
            const oddrisk=marketrisk?.odds_risk.find(item=>item.odd_name==id)
            
        if (oddrisk?.risk) {
            return oddrisk?.risk;
        }else{
            return null
        }}else{
            return null
        }
        }

        const risksee2=(id,market)=>{
            const nowrisk = risksee(id,market)
       
                const calculatedRiskOdd = runnersWithRiskOdd.length  
                ? runnersWithRiskOdd.find(riskitem => riskitem?.runnerName === id)?.calculatedRisk 
                : null;
    
            let displayValue = '';
    
            if (calculatedRiskOdd != 0&& (nowrisk + calculatedRiskOdd != 0)) {
                displayValue = `>> ${(nowrisk + calculatedRiskOdd).toFixed(2)}`;
                return displayValue
            }else{
                return null
            }
            
 

    }

    const risksee2color=(id,market)=>{
        const nowrisk = risksee(id,market)

    const calculatedRiskOdd = runnersWithRiskOdd.length  
        ? runnersWithRiskOdd.find(riskitem => riskitem?.runnerName === id)?.calculatedRisk 
        : null;

    let displayValue = '';

    if (calculatedRiskOdd != 0&& (nowrisk + calculatedRiskOdd != 0)) {
        displayValue = (nowrisk + calculatedRiskOdd).toFixed(2)
        return displayValue
    }else{
        return null
    }
}

    return (
        <>
            <Grid container alignItems="center" key={activemarket.market+"A"} sx={{ alignItems: "center", alignContent: "center", marginTop: !isMobile && "15px", borderBottom: "solid 2px", backgroundColor: "#272727" }} >
                <Grid item xs={6} >
                    <Typography variant="h6" gutterBottom sx={{ marginLeft: "10px", marginTop: "5px" }}>
                        {selectedmarket?.marketName}
                    </Typography>
                </Grid>
                <Grid item xs={6} >
                    <Typography variant="h6" gutterBottom sx={{ marginRight: "10px", marginTop: "5px", textAlign: "end", fontSize: "0.9rem", color: "#7b7b7b" }}>
                        EŞLEŞMİŞ : {selectedmarket?.totalMatched}
                    </Typography>
                </Grid>
            </Grid>
            <Paper style={{ padding: 20, margin: '2px auto' }} key={activemarket.market+"BB"}>

                {selectedmarket?.runners?.map((item, index) => (

                    <React.Fragment key={index+"DET"}>
                        <Grid container alignItems="center" key={item?.runnerName+"4"} spacing={2} sx={{ paddingTop: "4px", borderBottom: "solid 1px #6d6d6d", paddingBottom: "3px" }}>
                            <Grid item xs={6} sm={6} md={4}>
                                <Typography variant="h6" sx={{ color: "#d5d5d5", fontSize: "1.1rem" }}>{item?.runnerName}</Typography>
            <Typography key={recheck}  sx={{ color: risksee(item.runnerName,selectedmarket?.marketName)>0?"#19a900":risksee(item.runnerName,selectedmarket?.marketName)==0?"#333":"#f30000", fontSize: "0.7rem" }}>
               { risksee(item.runnerName,selectedmarket?.marketName)} <span style={{color:risksee2color(item.runnerName,selectedmarket?.marketName)>0?"#19a900":risksee2color(item.runnerName,selectedmarket?.marketName)==0?"#333":"#f30000"}}>{risksee2(item.runnerName,selectedmarket?.marketName)}</span>
            </Typography>

                            </Grid>
                            <Grid item xs={6} sm={6} md={8} sx={{ position: 'relative' }}>
                                {selectedmarket?.status !== "OPEN" &&
                                    <Typography sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 2,
                                        color: 'white',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        border: "solid 1px",
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: "#ffb80c",
                                        marginTop: "10px",
                                        maxHeight: "48px",
                                        backgroundColor: 'rgba(0, 0, 0, 0.85)' // Yarı saydam arka plan
                                    }}>
                                        {selectedmarket?.why?selectedmarket?.why:"SUSPEND"}
                                    </Typography>}
                                <Grid container >
                                    <Grid item xs={6} sm={6} md={6} >
                                        <Grid container sx={{ alignItems: "center" }}>
                                            {!isMobile &&
                                                <>
                                                    <Grid item xs={3.8} sx={{ marginRight: "2px" }}>
                                                        <Button variant="contained" color="primary" sx={{ minHeight: "37px", backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "BACK" &&
                                                        itemd.index === 2 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === selectedmarket.marketId
                                                    ) ? ("#ffb80c") : ("#ffb80c00"),'&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "BACK" &&
                                                            itemd.index === 2 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === selectedmarket.marketId
                                                        ) ? ("#ffb80c") : ("#a6d8ff") }, border: "solid 1px #ffb80c", boxSizing: "border-box" }} onClick={() => handleExpandClick(matchid, "BACK", item?.ex?.availableToBack[2]?.price, 2, item?.runnerName, item?.selectionId, selectedmarket.marketName, matchname, selectedmarket.marketId,1)} fullWidth>
                                                            <Stack direction="column" spacing={0}>
                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                    {item?.ex?.availableToBack[2]?.price}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                    {item?.ex?.availableToBack[2]?.size}
                                                                </Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={3.8} sx={{ marginRight: "2px" }}>
                                                        <Button variant="contained" color="primary" sx={{ minHeight: "37px",backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "BACK" &&
                                                        itemd.index === 1 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === selectedmarket.marketId
                                                    ) ? ("#ffb80c") : ("#ffb80c00"),'&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "BACK" &&
                                                            itemd.index === 1 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === selectedmarket.marketId
                                                        ) ? ("#ffb80c") : ("#a6d8ff") },  border: "solid 1px #ffb80c", boxSizing: "border-box" }} onClick={() => handleExpandClick(matchid, "BACK", item?.ex?.availableToBack[1]?.price, 1, item?.runnerName, item?.selectionId, selectedmarket.marketName, matchname, selectedmarket.marketId,1)} fullWidth>
                                                            <Stack direction="column" spacing={0}>
                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                    {item?.ex?.availableToBack[1]?.price}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                    {item?.ex?.availableToBack[1]?.size}
                                                                </Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12} sm={3.8} md={3.8} sx={{ marginRight: "2px" }}>
                                                <Button variant="contained" color="primary" sx={{ minHeight: "37px", backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "BACK" &&
                                                        itemd.index === 0 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === selectedmarket.marketId
                                                    ) ? ("#ffb80c") : ("#a6d8ff"),'&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "BACK" &&
                                                            itemd.index === 0 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === selectedmarket.marketId
                                                        ) ? ("#ffb80c") : ("#a6d8ff") }, color: "#000", border: "solid 1px #a6d8ff" }} onClick={() => handleExpandClick(matchid, "BACK", item?.ex?.availableToBack[0]?.price, 0, item?.runnerName, item?.selectionId, selectedmarket.marketName, matchname, selectedmarket.marketId,1)} fullWidth>
                                                    <Stack direction="column" spacing={0}>
                                                        <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                            {item?.ex?.availableToBack[0]?.price}
                                                        </Typography>
                                                        <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                            {item?.ex?.availableToBack[0]?.size}
                                                        </Typography>
                                                    </Stack>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} >
                                        <Grid container sx={{ alignItems: "center" }}>
                                            <Grid item xs={12} sm={3.8} md={3.8} sx={{ marginRight: "2px" }}>
                                                <Button variant="contained" color="primary" sx={{
                                                    minHeight: "37px", backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "LAY" &&
                                                        itemd.index === 0 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === selectedmarket.marketId
                                                    ) ? ("#ffb80c") : ("#fac9d1"),'&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "LAY" &&
                                                            itemd.index === 0 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === selectedmarket.marketId
                                                        ) ? ("#ffb80c") : ("#fac9d1") }, color: "#000", border: "solid 1px #fac9d1"
                                                }} onClick={() => handleExpandClick(matchid, "LAY", item?.ex?.availableToLay[0]?.price, 0, item?.runnerName, item?.selectionId, selectedmarket.marketName, matchname, selectedmarket.marketId,1)} fullWidth>
                                                    <Stack direction="column" spacing={0}>
                                                        <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                            {item?.ex?.availableToLay[0]?.price}
                                                        </Typography>
                                                        <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                            {item?.ex?.availableToLay[0]?.size}
                                                        </Typography>
                                                    </Stack>

                                                </Button>
                                            </Grid>
                                            {!isMobile &&
                                                <>
                                                    <Grid item xs={3.8} sx={{ marginRight: "2px" }} >
                                                        <Button variant="contained" color="primary" sx={{ minHeight: "37px", backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "LAY" &&
                                                        itemd.index === 1 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === selectedmarket.marketId
                                                    ) ? ("#ffb80c") : ("#ffb80c00"),'&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "LAY" &&
                                                            itemd.index === 1 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === selectedmarket.marketId
                                                        ) ? ("#ffb80c") : ("#ffb80c00") }, border: "solid 1px #ffb80c", boxSizing: "border-box" }} onClick={() => handleExpandClick(matchid, "LAY", item?.ex?.availableToLay[1]?.price, 1, item?.runnerName, item?.selectionId, selectedmarket.marketName, matchname, selectedmarket.marketId,1)} fullWidth>
                                                            <Stack direction="column" spacing={0}>
                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                    {item?.ex?.availableToLay[1]?.price}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                    {item?.ex?.availableToLay[1]?.size}
                                                                </Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={3.8} sx={{ marginRight: "2px" }}>
                                                        <Button variant="contained" color="primary" sx={{ minHeight: "37px", backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "LAY" &&
                                                        itemd.index === 2 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === selectedmarket.marketId
                                                    ) ? ("#ffb80c") : ("#ffb80c00"),'&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "LAY" &&
                                                            itemd.index === 2 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === selectedmarket.marketId
                                                        ) ? ("#ffb80c") : ("#ffb80c00") }, border: "solid 1px #ffb80c", boxSizing: "border-box" }} onClick={() => handleExpandClick(matchid, "LAY", item?.ex?.availableToLay[2]?.price, 2, item?.runnerName, item?.selectionId, selectedmarket.marketName, matchname, selectedmarket.marketId,1)} fullWidth>
                                                            <Stack direction="column" spacing={0}>
                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                    {item?.ex?.availableToLay[2]?.price}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                    {item?.ex?.availableToLay[2]?.size}
                                                                </Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                </>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {selectedodddetail?.map((sitem,index)=>(
                           <>{sitem.oddid==item.selectionId&&sitem.location==1&&isMobile&&
                        <Grid item xs={12} key={sitem.oddid}>
                        <BetPlacingComponent setOpen={setOpen} riskcheck={riskcheck} setBalance={setBalance} setStatus={setStatus} selectedodddetail={selectedodddetail} setStatustext={setStatustext} setExpandedMatchId={setExpandedMatchId} setselectedodddetail={setselectedodddetail} type={sitem.type} price={sitem.price} oddname={sitem.oddname} marketname={sitem.marketname} matchname={sitem.matchname} matchid={sitem.matchId} oddid={sitem.oddid} marketid={sitem.marketid} index={index} />
                    </Grid>}</> 
                        ))}

                    </React.Fragment>
                ))}
            </Paper>
            <Typography variant="h5" gutterBottom sx={{ marginLeft: "10px", marginTop: "10px" }}>Popüler Marketler</Typography>
            <Grid container>
                {markets.map((items,index) =>
                (<Grid item xs={12} sm={12} md={5.8} key={index+"a"} sx={{ margin: "5px 5px" }}>
                    <Grid container alignItems="center" sx={{ alignItems: "center", alignContent: "center", borderBottom: "solid 2px", backgroundColor: "#272727" }} >
                        <Grid item xs={6} >
                            <Typography variant="h6" gutterBottom sx={{ marginLeft: "10px", marginTop: "5px" }}>
                                {items?.marketName}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="h6" gutterBottom sx={{ marginRight: "10px", marginTop: "5px", textAlign: "end", fontSize: "0.9rem", color: "#7b7b7b" }}>
                                EŞLEŞMİŞ : {items?.totalMatched}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Paper style={{ padding: 20, margin: '2px auto' }}>

                        {items?.runners?.map((item, index) =>(
                            
                            <React.Fragment key={index+"a"}>
                                <Grid container alignItems="center" spacing={2} sx={{ paddingTop: "4px", borderBottom: "solid 1px #6d6d6d", paddingBottom: "3px" }}>
                                    <Grid item xs={7} sm={6} md={6}>
                                        <Typography variant="h6" sx={{ color: "#d5d5d5", fontSize: "1.1rem" }}>{item?.runnerName}</Typography>
                                        <Typography key={recheck}  sx={{ color: risksee(item.runnerName,items?.marketName)>0?"#19a900":risksee(item.runnerName,items?.marketName)==0?"#333":"#f30000", fontSize: "0.7rem" }}>
               { risksee(item.runnerName,items?.marketName)} <span style={{color:risksee2color(item.runnerName,items?.marketName)>0?"#19a900":risksee2color(item.runnerName,items?.marketName)==0?"#333":"#f30000"}}>{risksee2(item.runnerName,items?.marketName)}</span>
            </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={5} sm={6} md={6} >
                                        <Grid container sx={{ position: 'relative' }}>
                                            {items?.status !== "OPEN" &&
                                                <Typography sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    zIndex: 2,
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                    border: "solid 1px",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: "#ffb80c",

                                                    backgroundColor: 'rgba(0, 0, 0, 0.85)' // Yarı saydam arka plan
                                                }}>
                                                    {items?.why?items?.why:"SUSPEND"}
                                                </Typography>}
                                            <Grid item xs={6} sm={6} md={6} >
                                                <Grid container sx={{ alignItems: "center" }}>
                                                    <Grid item xs={12} sx={{ marginRight: "2px" }}>
                                                        <Button variant="contained" color="primary" sx={{ minHeight: "37px", backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "BACK" &&
                                                        itemd.index === 0 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === items.marketId
                                                    ) ? ("#ffb80c") : ("#a6d8ff"),'&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "BACK" &&
                                                            itemd.index === 0 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === items.marketId
                                                        ) ? ("#ffb80c") : ("#a6d8ff") }, color: "#000", border: "solid 1px #a6d8ff" }} onClick={() => handleExpandClick(matchid, "BACK", item?.ex?.availableToBack[0]?.price, 0, item?.runnerName, item?.selectionId, items.marketName, matchname, items.marketId,2)} fullWidth>
                                                            <Stack direction="column" spacing={0}>
                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                    {item?.ex?.availableToBack[0]?.price}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                    {item?.ex?.availableToBack[0]?.size}
                                                                </Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Grid container sx={{ alignItems: "center" }}>
                                                    <Grid item xs={12} sx={{ marginRight: "2px" }}>
                                                        <Button variant="contained" color="primary" sx={{ minHeight: "37px",backgroundColor: selectedodddetail?.some(itemd =>
                                                        itemd.matchId === matchid &&
                                                        itemd.type === "LAY" &&
                                                        itemd.index === 0 &&
                                                        itemd.oddid === item.selectionId &&
                                                        itemd.marketid === items.marketId
                                                    ) ? ("#ffb80c") : ("#fac9d1"),          '&:hover, &:focus': {
                                                        backgroundColor: selectedodddetail?.some(itemd =>
                                                            itemd.matchId === matchid &&
                                                            itemd.type === "LAY" &&
                                                            itemd.index === 0 &&
                                                            itemd.oddid === item.selectionId &&
                                                            itemd.marketid === items.marketId
                                                        ) ? ("#ffb80c") : ("#fac9d1"), // Buton için hover ve focus durumları
                                                      },color: "#000", border: "solid 1px #fac9d1" }} onClick={() => handleExpandClick(matchid, "LAY", item?.ex?.availableToLay[0]?.price,0, item?.runnerName, item?.selectionId, items.marketName, matchname, items.marketId,2)} fullWidth>
                                                            <Stack direction="column" spacing={0}>
                                                                <Typography variant="body2" sx={{ lineHeight: '1', fontWeight: "600", fontSize: "0.975rem" }}>
                                                                    {item?.ex?.availableToLay[0]?.price}
                                                                </Typography>
                                                                <Typography variant="caption" sx={{ lineHeight: '1', fontSize: "0.7rem" }}>
                                                                    {item?.ex?.availableToLay[0]?.size}
                                                                </Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {selectedodddetail?.map((sitem,index)=>(
                           <>{sitem.oddid==item.selectionId&&sitem.location==2&&isMobile&&
                        <Grid item xs={12} key={sitem.oddid}>
                        <BetPlacingComponent recheck={recheck} riskcheck={riskcheck} selectedodddetail={selectedodddetail}  setBalance={setBalance} setRecheck={setRecheck} setOpen={setOpen} setStatus={setStatus} setStatustext={setStatustext} setExpandedMatchId={setExpandedMatchId} setselectedodddetail={setselectedodddetail} type={sitem.type} price={sitem.price} oddname={sitem.oddname} marketname={sitem.marketname} matchid={sitem.matchId}  matchname={sitem.matchname} oddid={sitem.oddid} marketid={sitem.marketid} index={index} />
                    </Grid>}</> 
                        ))}
                                </Grid>
                            </React.Fragment>
                        ))}

                    </Paper>
                </Grid>)

                )}

            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
            onClose={handleClose}
            color={status}
            variant="filled"
            sx={{ width: '100%',color:"#fff"}}
            >
            {statustext}  <Link to="/my-bets" style={{  color: '#000' }}>
                BAHİSLERİM
            </Link>
            </Alert>
            </Snackbar>
            </>
    );
};

export default EventDetail;
