import React, { useState,useEffect } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Grid, useMediaQuery,Drawer,IconButton,Snackbar,Alert  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import LigMenu from '../components/leaguemenu';
import { apiGetFeedRequest,apiRequest } from '../config';
import MatchOddsTable from '../components/sportbook/prematch';
import InPlayMatches from '../components/sportbook/in-play';
import MatchOddsTableDesktop from '../components/sportbook/prematchdektop';
import InPlayDesktop from '../components/sportbook/in-playdesktop';
import { useNavigate,Link  } from 'react-router-dom';
import SportbookHeader from '../components/sportbook/sportbookheader';
import DesktopSearch from '../components/desktopsearch';
import BettingTab from '../components/rightmenu';
import QuickLinks from '../components/quicklink';
import MarketMenu from '../components/marketmenu';
import MarketMenuMobil from '../components/marketmenumobil';
import EventDetail from '../components/sportbook/eventdetail';
import MatchTimeline from '../components/sportbook/live';
import Loading from '../components/loading';
import IframeComponent from '../components/sportbook/trackeriframe';
import MobileSearch from '../components/mobilsearch';
function LightBulbIcon(props) {

  return (
    <SvgIcon {...props}>
      <path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z" />
    </SvgIcon>
  );
}

export default function PreMatch({setBalance}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [eventType,setEventType]= useState();
  const [liveinfo,setLiveInfo]= useState();
  const [marketinfo,setMarketInfo]= useState();
  const [markets,setMarkets]= useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [markettoglemenu, setMarketTogleMenu] = useState(false);
  const [activemarket, setActiveMarket] = useState([]);
  const [matchname,setMatchname]=useState();
  const [matchid,setMatchid]=useState();
  const [openDate,setOpendate]=useState();
  const [competition,setCompetition]=useState();
  const [selectedodddetail, setselectedodddetail] = useState([]);
  const [expandedMatchId, setExpandedMatchId] = useState(null);
  const [opensnack, setOpenSnack] = useState(false);
  const [status,setStatus] = useState("error");
  const [statustext,setStatustext] = useState("");
  const isEvent=true;

  const [recheck, setRecheck] = useState(false);
  const [selectedmarket, setSelectedmarket] = useState();
  const [loader,setloader]= useState(true);
  const [countryid, setcountryid] = useState();
  const [competitonid, setCompetitonid] = useState();
  const [confirmedodd, setConfirmedodd] = useState([]);
  const [allreadyrisk, setAllreadyrisk] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setloader(true)
    setActiveMarket([])
    setMarketInfo();
    setLiveInfo();
    const fetchData = async () => {
        try {
            const response = await apiGetFeedRequest("/api/events/event?eventId=" + id , token);
            if (response?.startTime) {
              setEventType("live")
              if(response?.state){
                setLiveInfo(response.state)
              }
            }else{
              setEventType("pre")
            }
            response.openDate
            setMatchname(response.name)
            setMatchid(response.id)
            setCompetition(response.competitionName)
            setCompetitonid(response.competitionId)
            setcountryid(response.countryCode)
            setOpendate(response.openDate)
              const marketInfo = response.marketCatalogues.map(item => ({
                marketId: item.marketId,
                marketName: item.marketName
              }));
              setMarketInfo(marketInfo)
              setMarkets(response.marketCatalogues)
              setloader(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const intervalId = setInterval(fetchData, 2000);
    fetchData();
    return () => clearInterval(intervalId);

}, [id]);
useEffect(() => {
      
  document.title = matchname+" | Torexch";


}, [matchid]);
useEffect(() => {
  const token = localStorage.getItem("authToken");


  let params = { event_id: id };
  apiRequest("api/coupon/risk-management-coupons-user", params, token)
  .then(response => {
    setAllreadyrisk(response)
    }).catch(error => {
    console.error('Error with API requests:', error);

    });
}, [id,recheck])

const riskcheck =()=>{
  const token = localStorage.getItem("authToken");


  let params = { event_id: id };
  apiRequest("api/coupon/risk-management-coupons-user", params, token)
  .then(response => {
    setAllreadyrisk(response)
    }).catch(error => {
    console.error('Error with API requests:', error);

    });
}
  const toggleDrawer = (open) => (event) => {
    setIsDrawerOpen(open);
  };
  const marketTogle = (open) => {
    setMarketTogleMenu(open);
  };
  const handleNavigate = () => {
    navigate('/my-bets');
};
const handleClick = () => {
    setOpenSnack(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const reselectodd = (matchid, marketid, oddid, type) => {
    const runne = markets.find(item => item.marketId == marketid);
    const odd = runne?.runners?.find(items => items.selectionId == oddid);
  
    if (!runne || !odd) {
      console.error("Market or Odd not found");
      return;
    }
  
    const newDetail = {
      matchId: matchid,
      type: type == 0 ? "BACK" : "LAY",
      price: type == 0 ? odd?.ex?.availableToBack[0]?.price : odd?.ex?.availableToLay[0]?.price,
      index: 0,
      oddname: odd.runnerName,
      oddid: odd.selectionId,
      marketname: runne.marketName,
      matchname: matchname,  // Ensure matchname is defined in the scope
      marketid: runne.marketId,
      location: 1
    };
  
    setselectedodddetail(prevDetails => [...prevDetails, newDetail]);
  };
  const getnow=(id,marketid,selectionid,type)=>{
    const market = markets.find(m => m.marketName === marketid);
    if (market) {
      const runner = market.runners.find(r => r.runnerName === selectionid);
      if (runner) {
       if(type=="0"){
        return runner.ex.availableToBack[0].price
       }else{
        return runner.ex.availableToLay[0].price
       }
      }
    }

  }
  
  const isMobile = useMediaQuery('(max-width:1280px)');
  return (
    <>
    <Grid container >
      {!isMobile?
        <>
          <Grid item xs={1} sm={1} md={2} lg={1.7}>
            {marketinfo&&
            <MarketMenu isDrawerOpen={isDrawerOpen} matchid={matchid} marketInfo={marketinfo} competitonid={competitonid} competition={competition} countryid={countryid} activemarket={activemarket} toggleDrawer={toggleDrawer} setActiveMarket={setActiveMarket} />
          }
          </Grid>
          <Grid item xs={1} sm={1} md={8} lg={6.8}>
            {!markets.length>0 || loader ?
              <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "20px",marginTop:"15px",backgroundColor: "#121212" }}>
                     <Loading />
              </Grid>
              :
              <Grid sx={{marginTop:"20px"}}>
                <Grid container sx={{justifyContent:"flex-end"}} > 
                <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5}> 
                <SportbookHeader
          key={"event" + "head"}
          activeleague={"event"}
          mobile={false}
          activeleaguename={"Maç Detayı"}
        /></Grid>
                              <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5}> 
                              <DesktopSearch isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} isEvent={isEvent} />
                              </Grid>
                              </Grid>
               <MatchTimeline liveinfo={liveinfo} openDate={openDate} competition={competition} matchname={matchname} matchid={matchid} eventType={eventType} />
               <EventDetail recheck={recheck}  allreadyrisk={allreadyrisk}  riskcheck={riskcheck} setBalance={setBalance} setRecheck={setRecheck} markets={markets} activemarket={activemarket} selectedmarket={selectedmarket} setSelectedmarket={setSelectedmarket}  isMobile={isMobile} matchid={matchid} matchname={matchname} setConfirmedodd={setConfirmedodd}  selectedodddetail={selectedodddetail} setselectedodddetail={setselectedodddetail} expandedMatchId={expandedMatchId} setExpandedMatchId={setExpandedMatchId}/>
              </Grid>
              }
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={3.5} sx={{marginTop:"5px"}}>
            <BettingTab handleNavigate={handleNavigate} getnow={getnow} setBalance={setBalance} matchid={matchid} recheck={recheck} setRecheck={setRecheck} selectedodddetail={selectedodddetail} setOpenSnack={setOpenSnack} setStatus={setStatus} setStatustext={setStatustext} reselectodd={reselectodd} confirmedodd={confirmedodd} setConfirmedodd={setConfirmedodd} setselectedodddetail={setselectedodddetail} expandedMatchId={expandedMatchId} setExpandedMatchId={setExpandedMatchId}/>
          </Grid>
        </>
        :
        <>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {!markets.length>0 || loader ?
            <Grid container sx={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "20px",marginTop:"15px",backgroundColor: "#121212" }}>
                   <Loading />
            </Grid>
            :
            <Grid sx={{marginTop:"5px"}}>
              <MobileSearch isDrawerOpen={isDrawerOpen}  toggleDrawer={toggleDrawer}  isEvent={isEvent}/>
             <MatchTimeline liveinfo={liveinfo} openDate={openDate} competition={competition} matchname={matchname} matchid={matchid} eventType={eventType} key={matchid}/>
             <MarketMenuMobil isDrawerOpen={isDrawerOpen} setBalance={setBalance}  matchid={matchid} competition={competition} competitonid={competitonid} countryid={countryid}  marketInfo={marketinfo} activemarket={activemarket} marketTogle={marketTogle} setActiveMarket={setActiveMarket} />
             <EventDetail recheck={recheck} allreadyrisk={allreadyrisk}  riskcheck={riskcheck} setBalance={setBalance} setRecheck={setRecheck} markets={markets} activemarket={activemarket} selectedmarket={selectedmarket} setSelectedmarket={setSelectedmarket}  isMobile={isMobile} matchid={matchid} matchname={matchname} setConfirmedodd={setConfirmedodd}  selectedodddetail={selectedodddetail} setselectedodddetail={setselectedodddetail} expandedMatchId={expandedMatchId} setExpandedMatchId={setExpandedMatchId}/>
            </Grid>
          }
        </Grid>
        <Drawer
          anchor="left"
          open={markettoglemenu}
          onClose={()=>marketTogle(false)}
          sx={{
            "& .MuiDrawer-paper": {
              top: '91px', // Adjust this value based on your header height
              height: 'calc(100% - 91px)', // Adjust this value based on your header height
              width: '240px', // Adjust the width as needed
              position: 'relative', // Ensure relative positioning for absolute positioning of close button
            },
            "& .MuiBackdrop-root": {
              top: '91px', // Adjust this value based on your header height
              height: 'calc(100% - 91px)', // Adjust this value based on your header height
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
            },
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
            }}
            onClick={()=>marketTogle(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <MarketMenu isDrawerOpen={isDrawerOpen} matchid={matchid} marketInfo={marketinfo} competitonid={competitonid} isEvent={isEvent}  competition={competition} countryid={countryid} activemarket={activemarket} marketTogle={marketTogle} toggleDrawer={toggleDrawer} setActiveMarket={setActiveMarket} />
        </Drawer> 
      </>
        }
    </Grid>
                <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                color={status}
                variant="filled"
                sx={{ width: '100%',color:"#fff"}}
                >
                {statustext}  <Link to="/my-bets" style={{  color: '#000' }}>
                    BAHİSLERİM
                </Link>
                </Alert>
                </Snackbar>
                </>
  );
}
