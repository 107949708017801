import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, createTheme, ThemeProvider } from '@mui/material';
import SportsIcon from '@mui/icons-material/Sports';
import TimerIcon from '@mui/icons-material/Timer';
import HomeIcon from '@mui/icons-material/Home';

const theme = createTheme({
  typography: {
    fontSize: 16, // Varsayılan yazı boyutunu buradan ayarlayabilirsiniz
  },
});

const DesktopMenu = () => {
    const location = useLocation();
  
    const isActive = (path) => {
      return location.pathname === path;
    };
  
    return (
      <ThemeProvider theme={theme}>
        <List style={{ display: 'flex',marginLeft:"25px"}}>
          <ListItem
            button
            component={NavLink}
            to="/"
            activeClassName="Mui-selected"
            isActive={() => isActive('/')} // Burada isActive fonksiyonunu kullanarak kontrol ediyoruz
            sx={{
              borderBottom: isActive('/') ? '2px solid #ffb80c' : 'none',
              backgroundColor: isActive('/') ? '#383838' : 'inherit',
              justifyContent: 'flex-start',
              gap: '8px',
              paddingLeft: '16px',
            }}
          >
            <ListItemIcon sx={{minWidth:"26px"}} >
              <HomeIcon sx={{ color: '#d5d5d5' }} /> {/* Aktif ise ikon rengini beyaz yap */}
            </ListItemIcon>
            <ListItemText primary="Anasayfa" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/live"
            activeClassName="Mui-selected"
            isActive={() => isActive('/live')} // Burada isActive fonksiyonunu kullanarak kontrol ediyoruz
            sx={{
              borderBottom: isActive('/live') ? '2px solid #ffb80c' : 'none',
              backgroundColor: isActive('/live') ? '#383838' : 'inherit',
              justifyContent: 'flex-start',
              gap: '8px',
              paddingLeft: '16px',
            }}
          >
            <ListItemIcon sx={{minWidth:"26px"}} >
              <SportsIcon sx={{ color:'#d5d5d5' }} /> {/* Aktif ise ikon rengini beyaz yap */}
            </ListItemIcon>
            <ListItemText primary="Live" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/pre-match"
            activeClassName="Mui-selected"
            isActive={() => isActive('/pre-match')} // Burada isActive fonksiyonunu kullanarak kontrol ediyoruz
            sx={{
              borderBottom: isActive('/pre-match') ? '2px solid #ffb80c' : 'none',
              backgroundColor: isActive('/pre-match') ? '#383838' : 'inherit',
              justifyContent: 'flex-start',
              gap: '8px',
              paddingLeft: '16px',
            }}
          >
            <ListItemIcon sx={{minWidth:"26px"}} >
              <TimerIcon sx={{ color: '#d5d5d5' }} /> {/* Aktif ise ikon rengini beyaz yap */}
            </ListItemIcon>
            <ListItemText primary="Maç Öncesi"  sx={{ whiteSpace: 'nowrap' }} />
          </ListItem>
        </List>
      </ThemeProvider>
    );
  };
  

export default DesktopMenu;
