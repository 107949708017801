import React from "react";
import logo from "./logopng.png"; // PNG dosyasını import et

function Icon() {
  return (
    <img
      src={logo} // PNG dosyasının kaynağını buraya ekle
      alt="Logo"
      height="40px" // İstediğin boyut ayarı
    />
  );
}

export default Icon;
